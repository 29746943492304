import Vue from "vue"
import Router from "vue-router"
import Index from "./views/index.vue"
import List from "./views/list.vue"
import Detail from "./views/detail.vue"
import About from "./views/about.vue"
import Carousel from "./views/iframe/carousel.vue"
//import SsoFrame from "./views/ssoframe.vue"

import Gallary from "./views/gallary.vue"
import Category from "./views/admin/category.vue"
import Course from "./views/admin/course.vue"
import Chapter from "./views/admin/chapter.vue"
import Section from "./views/admin/section.vue"
import Content from "./views/admin/content.vue"
import Teacher from "./views/admin/teacher.vue"



Vue.use(Router);

export default new Router({
    mode: "history",
    // base: process.env.BASE_URL,
    routes: [{
        path: "*",
        redirect: "/index",
    }, {
        path: "/index",
        component: Index
    }, {
        path: "/list",
        component: List
    }, {
    //     path: "/ssoframe",
    //     component: SsoFrame
    // }, {
        path: "/gallary",
        component: Gallary,
    }, {    path: "/detail",
        component: Detail
    }, {
        path: "/about",
        component: About
    }, {
        path: "/carousel",
        component: Carousel,
        meta: {
            hide: true
        },
    }, {

            path: "/admin/category",
            name: "/admin/category",
            component: Category,
            meta: {
                loginRequire: true
            },
        }, {
            path: "/admin/course",
            name: "/admin/course",
            component: Course,
        meta: {
            loginRequire: true
        },
        }, {
            path: "/admin/chapter",
            name: "/admin/chapter",
            component: Chapter,
        meta: {
            loginRequire: true
        },
        }, {
            path: "/admin/section",
            name: "/admin/section",
            component: Section,
        meta: {
            loginRequire: true
        },
        }, {
            path: "/admin/content",
            name: "/admin/content",
            component: Content,
        meta: {
            loginRequire: true
        },
        }, {
            path: "/admin/teacher",
            name: "/admin/teacher",
            component: Teacher,
        meta: {
            loginRequire: true
        },



        } ]



})
