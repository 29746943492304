<template>
  <main role="main">




      <div class="container">

        <div class="row">
<!--          <BannerSlide :imageData="imageData" width="95%" height="200px" />-->
          <Carousel :imageData="imageData" width="200px" height="800px" ref="thiscarousel"/>
        </div>

      </div>


  </main>
</template>

<script>



 // import BannerSlide from "@/components/swiper/Carousel.vue";
  import Carousel from "@/components/swiper/Carousel.vue";

  export default {
    name: 'carousel',
    components: {   Carousel},
    data: function () {
      return {
        id: "",//courseid

        MEDIA_TYPE:MEDIA_TYPE,
        mediatype: MEDIA_TYPE.PICTURE.key,
        pictures: [],
        imageData: [],
        myHeight: '200 px'
      }
    },
    mounted() {
      let _this = this;
      _this.id = _this.$route.query.id;

      _this.list(1);
      _this.handleResize()
      window.addEventListener('resize', _this.handleResize);



    },
    methods: {

      handleResize() {


        this.myHeight = 200  ;

        },



      /**
       * 查询视频列表
       */
      list(page) {
        let _this = this;
        _this.imageData=[];

         _this.pictures=[];
        let pagesize =1000;

        Loading.show();

        _this.$ajax.post(process.env.VUE_APP_SERVER + '/web/section/list', {
          page: page,
          size: pagesize,
          chapterId: _this.chapterId || "", //
          courseId: _this.id, //
          media: _this.mediatype,
        }).then((response) => {
          Loading.hide();
          let resp = response.data;
          if (resp.success) {

              _this.pictures = resp.content.list;

              for(let i = 0, len =_this.pictures.length; i<len; i++) {
                      let o = _this.pictures[i];
                      let image1={};
                      image1.title = o.name;
                      image1.imgUrl =o.url;
                      _this.imageData.push(image1);
              }
            //this.$refs.thiscarousel.getSwiper1();

          }
        }).catch((response) => {
          console.log("error：", response);
        })
      },





    },
    beforeDestroy() {

      window.removeEventListener('resize', this.handleResize);
    }


  }
</script>



